import React from "react";
import { Preferences } from '@capacitor/preferences'
import { ClearPresistor } from "../state/Store";

type UserDataInterface = { initialized: boolean; loggedIn: boolean; user: any };
type MyContextInterface = {
  authInfo: UserDataInterface;
  initialize: () => Promise<boolean>;
  logOut: () => Promise<boolean>;
  logIn: () => Promise<boolean>;
};

export const AuthContext = React.createContext<MyContextInterface | undefined>(
  undefined
);

export const AuthProvider: React.FC = (props: any) => {
  const [authInfo, setAuthInfo] = React.useState<UserDataInterface>();

  const logOut = () => {
    return new Promise(async (resolve) => {
      const {value} =  await Preferences.get({key: "url" });
      window.localStorage.setItem("port", value?.replace(/\D/g, "") ?? "");
      window.localStorage.removeItem("_capuid");
      await Preferences.clear();
      ClearPresistor()
      setAuthInfo({ initialized: true, loggedIn: false, user: null });
      setTimeout(() => {
        return resolve(true);
      }, 1000);
    });
  };

  const logIn = () => {
    return new Promise(async (resolve) => {
      const { value } = await Preferences.get({ key: "auth" });
      let v = {
        initialized: true,
        loggedIn: true,
        user: value,
      };
      setAuthInfo(v);
      setTimeout(() => {
        return resolve(true);
      }, 1000);
    });
  };

  const initialize = async() => {
    const { value } = await Preferences.get({ key: "auth" });
    if (value !== null) {
      setAuthInfo({
        initialized: true,
        loggedIn: true,
        user: value,
      });
    } else {
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        user: null,
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authInfo,
        logOut: logOut,
        logIn: logIn,
        initialize,
      }}
      {...props}
    />
  );
};

export const useAuth = () => React.useContext(AuthContext);
