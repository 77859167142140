import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonFooter,
  IonText,
  IonToolbar,
  IonBadge,
  IonImg,
  IonButton,
  IonHeader
} from '@ionic/react';
import { Preferences } from '@capacitor/preferences'
import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import { barChartOutline, mapOutline,peopleCircleOutline, receiptOutline, personCircle, logOutOutline, globeOutline, storefrontOutline, cartOutline } from 'ionicons/icons';
import { useSelector } from "react-redux";
import { useAuth } from "./../data/sessions/Auth";
import { AppPage } from './../models/Data';
import { Browser } from '@capacitor/browser';
import { useTranslation } from 'react-i18next';
import { getRoleMenu } from '../services/Library';
import './Menu.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Menu: React.FC = () => {
  const { t } = useTranslation(["general"]);
  const [baseUrl, setBaseUrl] = useState<any>();
  const [port, setPort] = useState<any>();
  const { logOut } = useAuth()!;
  const location = useLocation();
  const user = useSelector((state:any) => state.user.value);
  let appPages: AppPage[] = [
    {
      title: t("module.visit"),
      url: "visit",
      icon: mapOutline,
    },
    {
      title: t("module.customer"),
      url: "customer",
      icon: storefrontOutline,
    },
    {
      title: t("module.customer-profile"),
      url: "customer_profile",
      icon: peopleCircleOutline,
    },
    {
      title: t("module.so"),
      url: "so",
      icon: cartOutline,
    },
    {
      title: t("module.bill"),
      url: "bill",
      icon: receiptOutline,
    },
  ];
  
  useConstructor(async () => {
    try {
      if (typeof user == "undefined" || !user){
        logOut()
      }
      const {value} =  await Preferences.get({key: "url" });
      const name = value?.replace(/\/\/|.+\/\//, '')
      setPort(value?.replace(/\D/g, ""));
      setBaseUrl(Number(name?.indexOf("jagonyaaki.com")) > 1 ? 'Utama' : 'Backup');      
    } catch (error) {
      logOut()
    }
  });

  const browserUrl = async (url:string) =>{
    await Browser.open({ url: `https://${url}` }); 
  }

  return (
    <IonMenu contentId="main" type="overlay" className="ion-no-padding">
      <IonHeader>
        <IonToolbar>
          <IonImg
            className="logo-menu"
            slot="start"
            src={"assets/img/logo-santini.png"}
          />
          <IonText
            color="light"
            slot="start"
            className="ion-text-center ion-padding-start"
          >
            <span>
              <strong>
                GFORCE
                <br />
                Sales System
              </strong>
            </span>
          </IonText>
          <IonText slot="end" color="light" className="ion-padding">
            <IonBadge color={baseUrl === "Utama" ? "success" : "secondary"}>
              {baseUrl}
            </IonBadge>
          </IonText>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        <IonListHeader>Menu</IonListHeader>
        <IonList id="inbox-list" className="ion-no-padding">
          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "home" ? "selected" : ""}
              routerLink={"home"}
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={barChartOutline} />
              <IonLabel>{t("module.home")}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          {appPages.map((appPage, index) => {
            let getRole = getRoleMenu(user.role_menu, appPage.url);
            return getRole?.role_menu_list ? (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerDirection="none"
                  lines="none"
                  routerLink={appPage.url}
                >
                  <IonIcon slot="start" icon={appPage.icon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            ) : (
              ""
            );
          })}
        </IonList>
        <IonListHeader>Link</IonListHeader>
        <IonList id="inbox-list" className="ion-no-padding">
          <IonMenuToggle autoHide={false}>
            <IonItem
              onClick={() => {
                browserUrl("ras.jagonyaaki.com:" + port + "/home/login");
              }}
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={globeOutline} />
              <IonLabel>RAS</IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                browserUrl("doms.jagonyaaki.com");
              }}
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={globeOutline} />
              <IonLabel>DOMS</IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                browserUrl("roms.jagonyaaki.com");
              }}
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={globeOutline} />
              <IonLabel>ROMS</IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                browserUrl("gforcesantini.com/product");
              }}
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={globeOutline} />
              <IonLabel>PRODUK</IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                browserUrl("docs-gss.jagonyaaki.com");
              }}
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={globeOutline} />
              <IonLabel>{t("documentation")}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <IonListHeader>General Setting</IonListHeader>
        <IonList id="inbox-list" className="ion-no-padding">
          <IonMenuToggle autoHide={false}>
            <IonItem
              routerLink="/profile"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={personCircle} />
              <IonLabel>{t("module.profile")}</IonLabel>
            </IonItem>
            <IonButton
              expand="block"
              className="ion-padding"
              onClick={async () => {
                await logOut();
              }}
            >
              <IonText color="light" className="ion-padding-end">
                {t("logout")}
              </IonText>
              <IonIcon color="light" src={logOutOutline}></IonIcon>
            </IonButton>
            <br />
          </IonMenuToggle>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonText slot="end" color="light" className="ion-padding-end">
            Ver {process.env.REACT_APP_VERSION},{" "}
            <span style={{ fontSize: "10px" }}>
              {
                " \x44\x65\x76\x20\x62\x79\x20\x42\x61\x68\x79\x75\x20\x53\x61\x6e\x63\x69\x6b\x6f"
              }
            </span>
          </IonText>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
