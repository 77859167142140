import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonCard,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonBadge,
  IonText,
  IonFab, 
  IonFabButton, 
  IonList,
  IonImg,
  IonFabList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonRefresher, 
  IonRefresherContent,
  IonPage,
  useIonAlert,
  IonTitle,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  useIonModal,
  IonCardHeader,
  IonRadioGroup,
  IonRadio,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonToggle,
  IonGrid,
} from '@ionic/react';
import React, { useEffect, useState} from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, arrowBackOutline, menuSharp, checkmarkCircleOutline, storefrontSharp, calendar, createOutline, personCircleOutline, checkmarkDoneCircleOutline, starOutline} from "ionicons/icons";
import { RestApi } from '../../services/Api';
import { CustomerProfile } from "../../models/Modal";
import { useTranslation } from 'react-i18next';
import { getNameDate, getRoleMenu } from '../../services/Library';
import { useSelector } from 'react-redux';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import Form from './Form';
import './Detail.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Detail: React.FC<CustomerProfile> = (props) =>{
  const [customerProfile, setCustomerProfile] = useState<any>([]);
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.value);
  const getRole = getRoleMenu(user.role_menu, "customer_profile");
  const [segment, setSegment] = useState<string>("information");
  const [customerProfileAddressList, setCustomerProfileAddressList] = useState<any>([]);
  const [customerProfileAddress, setCustomerProfileAddress] = useState<any>({
    customer_profile_address_id: 0,
    customer_profile_address_edit: 0,
    customer_profile_address_is_default: true,
    customer_profile_address_is_active: true,
    customer_profile_address_name: null,
    customer_profile_address: null,
  });
  const [segmentImage, setSegmentImage] = useState<string>(
    "customer_profile_identity_card_image"
  );
  let countTotal:number = 0;
  const status: any = [
    {
      name: t("cancel"),
      color: "danger",
    },
    {
      name: t("check"),
      color: "secondary",
    },
    {
      name: t("wait-approve"),
      color: "warning",
    },
    {
      name: t("approve"),
      color: "success",
    },
  ];

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ cssClass: "alert-button-confirm", text: "Ok" }],
    });
  };

  const handleDismissForm = () => {
    dismissForm();
    getCustomerDetailCustomerProfile(props.idCustomerProfile);
  };


  const [presentForm, dismissForm] = useIonModal(Form, {
    idCustomerProfile: props.idCustomerProfile,
    type: "edit",
    onDismiss: handleDismissForm,
  });

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
      getCustomerDetailCustomerProfile(props.idCustomerProfile)
      event.detail.complete();
  }

  const getCustomerDetailCustomerProfile = (id:string) => {
    if(!id) {
      props.onDismiss()
      return;
    }
    setLoaded(false)
    RestApi(`customer-profile/${id}`,'get',{},'application/json')
    .then(async(res:any) => {
        if (res.data.code === 200) {
          setCustomerProfile(res.data.data)
          setCustomerProfileAddressList(res.data.data.customer_profile_address);
          setLoaded(true)
        }else{
          props.onDismiss()
          massageAlert('error',res.data.message)
        }
    }).catch((err:any) => {
        props.onDismiss()
        massageAlert('error',err.message)
    });
  }

  const setValRadioAssessment = (val: any) => {
    return val.find((item: any) => item.selected === true);
  };

  const handleDismissFormCustomerProfileAddress = () => {
    dismissFormCustomerProfileAddress();
  };

  const [presentFormCustomerProfileAddress, dismissFormCustomerProfileAddress] =
  useIonModal(ModalFormCustomerProfileAddress, {
    customerProfileAddressList: customerProfileAddressList,
    customerProfileAddress: customerProfileAddress,
    onDismissFormCustomerProfileAddress: handleDismissFormCustomerProfileAddress,
  });

  useConstructor(() => {
    getCustomerDetailCustomerProfile(props.idCustomerProfile)
  });
    
  return (
    <IonPage>
      <IonHeader>
        {loaded ? (
          <IonToolbar class="toolbar-detail-customer-profile ion-no-padding ion-no-margin">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => props.onDismiss()}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonItem className="ion-no-padding" lines="none">
              <IonLabel className="ion-no-padding" color="light">
                <h4>
                  <strong>
                    {customerProfile.customer_profile_name},
                    {customerProfile.customer_profile_title + " "}
                  </strong>
                  ({customerProfile.customer_profile_customer_id})
                </h4>
              </IonLabel>
              <IonBadge
                slot="end"
                color={status[customerProfile.customer_profile_status].color}
              >
                {status[customerProfile.customer_profile_status].name}
              </IonBadge>
            </IonItem>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className="detail-customer-profile-content">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={`${t("loading")}`}
            refreshingSpinner="circles"
            refreshingText={`${t("loading")}`}
          ></IonRefresherContent>
        </IonRefresher>
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p className="ion-no-padding ion-no-margin">
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <hr className="ion-no-padding ion-no-margin" />
                <p className="ion-no-padding ion-no-margin">
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
                <hr className="ion-no-padding ion-no-margin" />
              </IonLabel>
            </IonItem>
          </IonList>
        )}
        {customerProfile && loaded ? (
          <>
            <IonCard className="ion-no-padding">
              <IonItem className="ion-no-padding ion-no-margin">
                <IonText className="ion-padding-start" color={"dark"}>
                  <strong>{t("module.customer-profile")}</strong>
                </IonText>
                <IonText slot="end">
                  {getNameDate(customerProfile.customer_profile_visit_date)}
                  <IonIcon color="danger" src={calendar} />
                </IonText>
              </IonItem>
              <IonItem className="ion-no-padding ion-no-margin">
                <IonText className="ion-padding-start ion-no-margin">
                  <span>
                    {t("note")} Survey :
                    {customerProfile.customer_profile_visit_note}
                  </span>
                  <span>
                    <IonIcon
                      size="small"
                      className="ion-no-padding"
                      src={personCircleOutline}
                    />
                    :
                    {customerProfile.customer_profile_create_by +
                      " - " +
                      getNameDate(
                        customerProfile.customer_profile_create_at,
                        true
                      )}
                  </span>
                </IonText>
              </IonItem>
              {customerProfile.customer_profile_check_at ? (
                <IonItem>
                  <IonText>
                    <span>
                      {t("note")} Periksa :
                      {customerProfile.customer_profile_check_note}
                    </span>
                    <span>
                      <IonIcon
                        size="small"
                        className="ion-no-padding"
                        src={checkmarkCircleOutline}
                      />
                      :
                      {customerProfile.customer_profile_check_by +
                        " - " +
                        getNameDate(
                          customerProfile.customer_profile_check_at,
                          true
                        )}
                    </span>
                  </IonText>
                </IonItem>
              ) : (
                ""
              )}
              {customerProfile.customer_profile_approve_at ? (
                <IonItem>
                  <IonText>
                    <span>
                      {t("note")} Disetujui :
                      {customerProfile.customer_profile_approve_note}
                    </span>
                    <span>
                      <IonIcon
                        size="small"
                        className="ion-no-padding"
                        src={checkmarkDoneCircleOutline}
                      />
                      :
                      {customerProfile.customer_profile_approve_by +
                        " - " +
                        getNameDate(
                          customerProfile.customer_profile_approve_at,
                          true
                        )}
                    </span>
                  </IonText>
                </IonItem>
              ) : (
                ""
              )}
            </IonCard>
            <IonCard className="ion-no-padding">
              <IonTitle className="ion-padding-start ion-no-margin">
                <strong>Customer Profile Form (CPF)</strong>
              </IonTitle>
              <hr />
              <IonSegment
                scrollable={true}
                value={segment}
                className="ion-no-padding ion-no-margin"
              >
                <IonSegmentButton
                  value="information"
                  onClick={() => {
                    setSegment("information");
                  }}
                >
                  <IonLabel>{t("information")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="address"
                  onClick={() => {
                    setSegment("address");
                  }}
                >
                  <IonLabel>{t("address")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="contact"
                  onClick={() => {
                    setSegment("contact");
                  }}
                >
                  <IonLabel>{t("contact")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="detail"
                  onClick={() => {
                    setSegment("detail");
                  }}
                >
                  <IonLabel>{t("detail")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="assessment"
                  onClick={() => {
                    setSegment("assessment");
                  }}
                >
                  <IonLabel>SPKO</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <IonCardContent className="ion-no-padding ion-no-margin">
                {segment === "information" && (
                  <IonText className="ion-padding-start">
                    <p className="ion-no-padding ion-no-margin">
                      Handphone :
                      <strong>{customerProfile.customer_profile_phone}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Telpon :
                      <strong>{customerProfile.customer_profile_phone}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Fax :
                      <strong>{customerProfile.customer_profile_fax}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Email :
                      <strong> {customerProfile.customer_profile_email}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Status :
                      <strong>
                        {customerProfile.customer_profile_is_active
                          ? "AKTIF"
                          : "TIDAK AKTIF"}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />

                    <p className="ion-no-padding ion-no-margin">
                      Area :
                      <strong>
                        {customerProfile.subarea_name} -
                        {customerProfile.area_name}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Kota :
                      <strong> {customerProfile.customer_profile_city}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Propinsi :
                      <strong> {customerProfile.customer_profile_state}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Negara :
                      <strong>
                        {customerProfile.customer_profile_country}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Kode Pos :
                      <strong>
                        {customerProfile.customer_profile_zipcode}
                      </strong>
                    </p>
                  </IonText>
                )}
                {segment === "contact" && (
                  <IonText className="ion-padding-start">
                    <p className="ion-no-padding ion-no-margin">
                      Nama Kontak :
                      <strong>
                        {customerProfile.customer_profile_contact_name}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Tanggal Lahir :
                      <strong>
                        {getNameDate(
                          customerProfile.customer_profile_contact_birthday
                        )}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Jabatan :
                      <strong>
                        {customerProfile.customer_profile_contact_title}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Hobby :
                      <strong>
                        {customerProfile.customer_profile_contact_hobby}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Handphone :
                      <strong>
                        {customerProfile.customer_profile_contact_mobile}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Email :
                      <strong>
                        {customerProfile.customer_profile_contact_email}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Nama KTP :
                      <strong>
                        {customerProfile.customer_profile_identity_card_name}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      No. KTP :
                      <strong>
                        {customerProfile.customer_profile_identity_card_number}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Nama NPWP :
                      <strong>
                        {customerProfile.customer_profile_tax_name}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Nomor NPWP :
                      <strong>
                        {customerProfile.customer_profile_tax_number}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                  </IonText>
                )}
                {segment === "address" && (
                  <>
                    {customerProfile.customer_profile_address.map(
                      (address: any) => (
                        <IonCard
                          key={address.customer_profile_address_id}
                          className="ion-margin"
                          style={{
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: "12px",
                          }}
                          onClick={() => {
                            presentFormCustomerProfileAddress({
                              initialBreakpoint: 0.90,
                              breakpoints: [0, 1.0],
                              handleBehavior: "none",
                            });
                            setCustomerProfileAddress(address);
                          }}
                        >
                          <IonCardContent>
                            <IonRow className="ion-no-padding ion-no-margin ion-align-items-center">
                              <IonCol
                                size="6"
                                className="ion-no-padding ion-no-margin"
                              >
                                <IonBadge
                                  color={
                                    address.customer_profile_address_is_active ===
                                    1
                                      ? "success"
                                      : "danger"
                                  }
                                >
                                  {address.customer_profile_address_is_active ===
                                  1
                                    ? "Aktif"
                                    : "InAktif"}
                                </IonBadge>
                              </IonCol>
                              <IonCol
                                size="6"
                                className="ion-no-padding ion-no-margin ion-text-end"
                              >
                                {address.customer_profile_address_is_default ===
                                  1 && (
                                  <IonIcon
                                    icon={starOutline}
                                    color="warning"
                                    size="small"
                                  />
                                )}
                              </IonCol>
                              <IonCol
                                size="12"
                                className="ion-no-padding ion-no-margin"
                              >
                                <IonText>
                                  <h3
                                    style={{
                                      margin: 0,
                                      color: "#333",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {address.customer_profile_address_name}
                                  </h3>
                                  <p style={{ color: "#666" }}>
                                    {address.customer_profile_address}
                                  </p>
                                  <hr className="ion-no-padding ion-no-margin" />
                                </IonText>
                              </IonCol>
                            </IonRow>
                          </IonCardContent>
                        </IonCard>
                      )
                    )}
                  </>
                )}
                {segment === "detail" && (
                  <>
                    <IonText className="ion-padding-start">
                      <p className="ion-no-padding ion-no-margin">
                        Website URL :
                        <strong> {customerProfile.customer_profile_url}</strong>
                      </p>
                      <hr className="ion-no-padding ion-no-margin" />
                      <p className="ion-no-padding ion-no-margin">
                        Keterangan :
                        <strong>
                          {customerProfile.customer_profile_remark}
                        </strong>
                      </p>
                      <hr className="ion-no-padding ion-no-margin" />
                    </IonText>
                  </>
                )}
                {segment === "assessment" && (
                  <IonCard className="ion-no-padding ion-no-margin">
                    <IonCardHeader className="ion-no-padding ion-no-margin">
                      <IonItem>
                        <IonText>
                          <strong>SPKO</strong>
                        </IonText>
                        <IonText slot="end">
                          <strong>Total</strong>
                        </IonText>
                      </IonItem>
                    </IonCardHeader>
                    <IonCardContent className="ion-no-padding ion-no-margin">
                      {customerProfile.assessment.map(
                        (valAsType: any, keyAsType: number) => {
                          let countSubTotal = 0;
                          return (
                            <IonCard key={keyAsType}>
                              <IonCardHeader className="ion-no-padding ion-no-margin">
                                <IonText>
                                  <strong>
                                    {valAsType.assessment_type_name}
                                  </strong>
                                </IonText>
                              </IonCardHeader>
                              <IonCardContent>
                                {valAsType.assessment.map(
                                  (valAs: any, keyAs: number) => {
                                    return (
                                      <div key={keyAs}>
                                        <IonItem className="ion-no-padding ion-no-margin">
                                          <IonText>
                                            {valAs.assessment_name}
                                          </IonText>
                                          <IonText slot="end">
                                            {valAs.assessment_weight}
                                          </IonText>
                                        </IonItem>
                                        <IonList>
                                          <IonRadioGroup
                                            value={setValRadioAssessment(
                                              valAs.assessment_value
                                            )}
                                          >
                                            {valAs.assessment_value.map(
                                              (value: any, key: number) => {
                                                return (
                                                  <IonItem
                                                    className="ion-no-padding ion-no-margin"
                                                    key={key}
                                                    lines="none"
                                                  >
                                                    <IonRadio
                                                      disabled={true}
                                                      className="ion-no-padding ion-no-margin"
                                                      value={value}
                                                      justify="space-between"
                                                    >
                                                      <strong
                                                        className="ion-text-wrap"
                                                        style={{
                                                          color: "strong",
                                                        }}
                                                      >
                                                        {value.name} (
                                                        {value.weight})
                                                      </strong>
                                                    </IonRadio>
                                                  </IonItem>
                                                );
                                              }
                                            )}
                                          </IonRadioGroup>
                                        </IonList>
                                        <IonItem>
                                          <IonText slot="end">
                                            {(() => {
                                              let selected =
                                                setValRadioAssessment(
                                                  valAs.assessment_value
                                                );
                                              let total = Math.round(
                                                selected
                                                  ? Number(selected.weight) *
                                                      valAs.assessment_weight
                                                  : 0
                                              );
                                              countSubTotal += total;
                                              countTotal += total;
                                              return total?.toFixed(1);
                                            })()}
                                          </IonText>
                                        </IonItem>
                                      </div>
                                    );
                                  }
                                )}
                              </IonCardContent>
                              <IonItem className="ion-no-padding ion-no-margin">
                                <IonText slot="end">
                                  <strong>
                                    Sub Total = {countSubTotal?.toFixed(1)}
                                  </strong>
                                </IonText>
                              </IonItem>
                            </IonCard>
                          );
                        }
                      )}
                    </IonCardContent>
                    <IonItem className="ion-no-padding ion-no-margin">
                      <IonText slot="end" class="ion-text-end">
                        <p className="ion-no-padding ion-no-margin">
                          <strong>Total = {countTotal?.toFixed(1)}</strong>
                        </p>
                        <hr className="ion-no-padding ion-no-margin" />
                        <IonText color="primary">
                          <p className="ion-no-padding ion-no-margin">
                            {(() => {
                              if (customerProfile.setting[1]) {
                                let setting_json_index_assessment = JSON.parse(
                                  customerProfile.setting[1].settings_value
                                );
                                const itemInRange =
                                  setting_json_index_assessment.sort(
                                    (a: any, b: any) =>
                                      Number(b.attaintement_rate) -
                                      Number(a.attaintement_rate)
                                  );
                                const item = itemInRange.find(
                                  (item: any) =>
                                    countTotal >= item.attaintement_rate
                                );
                                return `${item.class} - (${item.category}), ${item.decision}`;
                              }
                            })()}
                          </p>
                          <hr className="ion-no-padding ion-no-margin" />
                        </IonText>
                      </IonText>
                    </IonItem>
                    {customerProfile.setting[0] && (
                      <IonItem className="ion-no-padding ion-no-margin">
                        <div
                          className="ion-no-padding ion-no-margin"
                          dangerouslySetInnerHTML={{
                            __html: customerProfile.setting[0].settings_value,
                          }}
                        ></div>
                      </IonItem>
                    )}
                  </IonCard>
                )}
              </IonCardContent>
            </IonCard>
            <IonCard className="ion-no-padding">
              <IonTitle className="ion-padding-start ion-no-margin">
                <strong>Upload Foto Pelanggan</strong>
              </IonTitle>
              <IonCardContent className="ion-no-padding ion-no-margin">
                <IonSegment
                  scrollable={true}
                  value={segmentImage}
                  className="ion-no-padding ion-no-margin"
                >
                  <IonSegmentButton
                    value="customer_profile_identity_card_image"
                    onClick={() => {
                      setSegmentImage("customer_profile_identity_card_image");
                    }}
                  >
                    <IonLabel>KTP</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_profile_image1"
                    onClick={() => {
                      setSegmentImage("customer_profile_image1");
                    }}
                  >
                    <IonLabel>Foto 1</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_profile_image2"
                    onClick={() => {
                      setSegmentImage("customer_profile_image2");
                    }}
                  >
                    <IonLabel>Foto 2</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_profile_image3"
                    onClick={() => {
                      setSegmentImage("customer_profile_image3");
                    }}
                  >
                    <IonLabel>Foto 3</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_profile_image4"
                    onClick={() => {
                      setSegmentImage("customer_profile_image4");
                    }}
                  >
                    <IonLabel>Foto 4</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                <IonImg
                  src={
                    customerProfile[segmentImage] ??
                    `assets/img/image_not_available.png`
                  }
                />
              </IonCardContent>
            </IonCard>
          </>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
      {loaded && customerProfile.customer_profile_status !== 3 && (
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton className="fab-up" size="small">
            <IonIcon size="small" icon={menuSharp} />
          </IonFabButton>
          <IonFabList side="top">
            {(customerProfile.customer_profile_status === 1 ||
              customerProfile.customer_profile_status === 2) &&
              getRole?.role_menu_edit === 1 &&
              getRole?.role_menu_add === 1 && (
                <IonFabButton
                  data-desc={`${t("edit")}`}
                  onClick={() => {
                    presentForm({
                      cssClass: "modal-fullscreen",
                    });
                  }}
                >
                  <IonIcon icon={createOutline} />
                </IonFabButton>
              )}
          </IonFabList>
        </IonFab>
      )}
    </IonPage>
  );
};

const ModalFormCustomerProfileAddress: React.FC<any> = (props) => {
  const { t } = useTranslation(["general"]);
  const user = useSelector((state: any) => state.user.value);
  const [customerProfileAddressList, setCustomerProfileAddressList] = useState<any>(props.customerProfileAddressList ?? []);
  const [onLoadInfo, setOnLoadInfo] = useState<boolean>(false);
  const [customerProfileAddress, setCustomerProfileAddress] = useState<any>(
    props.customerProfileAddress ?? {
      customer_profile_address_id: 0,
      customer_profile_address_is_default: true,
      customer_profile_address_is_active: true,
      customer_profile_address_name: null,
      customer_profile_address_edit: 0,
      customer_profile_address: null,
      customer_profile_address_lng: user.branchs.branch_lng,
      customer_profile_address_lat: user.branchs.branch_lat,
    }
  );

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP || "",
  });


  useEffect(() => {
    setCustomerProfileAddressList(props.customerProfileAddressList ?? []);
    setCustomerProfileAddress(
      props.customerProfileAddress ?? {
        customer_profile_address_id: 0,
        customer_profile_address_edit: 0,
        customer_profile_address_is_default: true,
        customer_profile_address_is_active: true,
        customer_profile_address_name: null,
        customer_profile_address: null,
        customer_profile_address_lng: user.branchs.branch_lng,
        customer_profile_address_lat: user.branchs.branch_lat,
      }
    );
  }, [props.customerProfileAddress, props.customerProfileAddressList, user]);

  return (
    <IonPage>
      <IonCard className="ion-no-padding ion-no-margin">
        <IonCardHeader>
          <IonText>{`${t(props.typeFormCustomerProfileAddress)} ${t(
            "list"
          )} ${t("address")}`}</IonText>
        </IonCardHeader>
        <IonCardContent className="ion-no-padding ion-no-margin">
          <IonGrid className="ion-no-padding ion-no-margin">
            <IonRow className="ion-no-padding ion-no-margin">
              <IonCol className="ion-no-padding ion-no-margin" size="6">
                <IonItem>
                  <IonToggle
                    id="customer_profile_address_is_default"
                    name="customer_profile_address_is_default"
                    color="danger"
                    enableOnOffLabels={true}
                    disabled={true}
                    checked={
                      customerProfileAddressList.length === 0
                        ? true
                        : customerProfileAddress.customer_profile_address_is_default
                    }
                  >
                    {t("is_default")}
                  </IonToggle>
                </IonItem>
              </IonCol>
              <IonCol className="ion-no-padding ion-no-margin" size="6">
                <IonItem>
                  <IonToggle
                    placeholder={`${t("name")} ${t("address")}`}
                    color="success"
                    id="customer_profile_address_is_active"
                    name="customer_profile_address_is_active"
                    enableOnOffLabels={true}
                    onClick={() =>
                      setCustomerProfileAddress((prevState: any) => ({
                        ...prevState,
                        customer_profile_address_is_active:
                          !customerProfileAddress.customer_profile_address_is_active,
                      }))
                    }
                    checked={
                      customerProfileAddress.customer_profile_address_is_active
                    }
                  >
                    {t("is_active")}
                  </IonToggle>
                </IonItem>
              </IonCol>
              <IonCol size="12" className="ion-no-padding ion-no-margin">
                <IonItem>
                  <IonInput
                    type="text"
                    labelPlacement="floating"
                    placeholder={`${t("name")} ${t("address")}`}
                    label={`${t("name")} ${t("address")}`}
                    id="customer_profile_address_name"
                    name="customer_profile_address_name"
                    required={true}
                    disabled={true}
                    value={customerProfileAddress.customer_profile_address_name}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="ion-no-padding ion-no-margin" size="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">{t("address")}</IonLabel>
                  <IonTextarea
                    id="customer_profile_address"
                    name="customer_profile_address"
                    placeholder={`${t("input")} ${t("address")}`}
                    rows={3}
                    autoGrow={true}
                    required={true}
                    disabled={true}
                    value={customerProfileAddress.customer_profile_address}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="ion-no-padding ion-no-margin" size="12">
                {isLoaded && (
                  <>
                    <GoogleMap
                      mapContainerStyle={{
                        display: "inline-block",
                        width: "100%",
                        height: "45vh",
                      }}
                      center={{
                        lat: Number(
                          customerProfileAddress.customer_profile_address_lat ??
                            user.branchs.branch_lat
                        ),
                        lng: Number(
                          customerProfileAddress.customer_profile_address_lng ??
                            user.branchs.branch_lng
                        ),
                      }}
                      zoom={17}
                      options={{
                        gestureHandling: "none",
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        zoomControl: false,
                        clickableIcons: false,
                        keyboardShortcuts: false,
                      }}
                    >
                      <Marker
                        icon={{
                          path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                          fillColor: "yellow",
                          fillOpacity: 0.9,
                          scale: 2,
                          strokeColor: "gold",
                          strokeWeight: 2,
                        }}
                        position={{
                          lat: Number(
                            customerProfileAddress.customer_profile_address_lat ??
                              user.branchs.branch_lat
                          ),
                          lng: Number(
                            customerProfileAddress.customer_profile_address_lng ??
                              user.branchs.branch_lng
                          ),
                        }}
                        onClick={() => setOnLoadInfo(!onLoadInfo)}
                      />
                      {onLoadInfo && (
                        <InfoWindow
                          onCloseClick={() => setOnLoadInfo(!onLoadInfo)}
                          position={{
                            lat: Number(
                              customerProfileAddress.customer_profile_address_lat ??
                                user.branchs.branch_lat
                            ),
                            lng: Number(
                              customerProfileAddress.customer_profile_address_lng ??
                                user.branchs.branch_lng
                            ),
                          }}
                        >
                          <IonText className="ion-no-padding">
                            <h4>
                              <IonIcon
                                size="small"
                                className="ion-no-padding"
                                src={storefrontSharp}
                              />
                              <strong>
                                {
                                  customerProfileAddress.customer_profile_address_name
                                }
                              </strong>
                            </h4>
                          </IonText>
                        </InfoWindow>
                      )}
                      <IonFab slot="fixed" vertical="bottom" horizontal="start">
                        <IonBadge color={"medium"}>
                          <h3>
                            {`${Number(
                              customerProfileAddress.customer_profile_address_lat ??
                                user.branchs.branch_lat
                            )},${Number(
                              customerProfileAddress.customer_profile_address_lng ??
                                user.branchs.branch_lng
                            )}`}
                          </h3>
                        </IonBadge>
                      </IonFab>
                    </GoogleMap>
                    <style>
                      {`
                        .gm-style .gmnoprint, .gmnoprint {
                          display: none !important;
                        }
                      `}
                    </style>
                  </>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </IonPage>
  );
};

export default Detail;
